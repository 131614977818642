import React, { useState } from 'react';
import { Link } from "@reach/router";
import Cookies from "js-cookie";
import { withTranslation } from 'react-i18next';

const CookieNotice = ({ t, i18n }) => {
    const [cookieVisible, setCookieVisible] = useState(null);

    if (Cookies.get('cookie-notice') === undefined && cookieVisible !== true) {
        setCookieVisible(true);
    }

    function handleClose() {
        Cookies.set('cookie-notice', true, { expires: 365 });
        setCookieVisible(false);
    }

    if (cookieVisible) {
        return (
            <div className="c-cookie-notice">
                <div className="container">
                    <div className="c-cookie-notice__details">
                    <span className="c-cookie-notice__description">
                        <span>{t('cookie-notice.description')}</span>
                        <Link className="u-text--underline" to="/terms-and-conditions/">{t('cookie-notice.link-label')}</Link>
                    </span>
                        <button onClick={() => handleClose()} className="c-button c-button--close c-cookie-notice__btn" aria-label={t('cookie-notice.button-label')}>
                            <i className="fal fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <>
            </>
        );
    }
};

export default withTranslation()(CookieNotice);
