import React, { useEffect, useState, useRef, useContext } from 'react';
import { withTranslation } from 'react-i18next';

import HeroCaption from './component.caption';

import ThemeContext from '../../context/context.theme';

/**
 * Returns the classes for the video element dependent on present options.
 *
 * @param {*} options
 * @returns
 */
function getVideoClasses(options = {}) {
    const { loaded, emphasis } = options;
    const classes = ['c-hero-banner__wrapper', 'c-hero-banner__wrapper--video'];

    if (loaded) {
        classes.push('c-hero-banner__wrapper--loaded');
    }

    if (emphasis) {
        classes.push('c-hero-banner__wrapper--emphasis');
    }

    return classes.join(' ');
}

const HeroVideo = ({ title, subtitle, emphasis, video, poster, t }) => {
    const theme = useContext(ThemeContext);
    const [loaded, setLoaded] = useState(false);
    const [wrapperClasses, setWrapperClasses] =  useState(getVideoClasses({ loaded, emphasis }));
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef && videoRef.current) {
            // Add callback when video is loaded if the video isn't already loaded
            if (videoRef.current.readyState === 0) {
                videoRef.current.addEventListener('loadeddata', () => {
                    setLoaded(true);
                });
            } else {
                setLoaded(true);
            }
        } else {
            setLoaded(true);
        }
    }, []);

    // Update classes if loaded state is updated
    useEffect(() => {
        setWrapperClasses(getVideoClasses({ loaded, emphasis }));
     }, [loaded, emphasis]);

    return ((video && theme.allowVideo) &&
        <div className="c-hero-banner__wrapper c-hero-banner__wrapper--video" className={wrapperClasses}>
            <div className="c-hero-banner__video-wrapper">
                <video ref={videoRef} className="c-hero-banner__video" poster={poster} playsInline autoPlay muted loop>
                    {video.videoMp4 && (<source src={video.videoMp4.mediaItemUrl} type="video/mp4"/>)}
                    {video.videoWebm && (<source src={video.videoWebm.mediaItemUrl} type="video/webm"/>)}
                    {t("distributor.hero-banner.video.not-supported")}
                </video>
                <HeroCaption title={title} subtitle={subtitle} emphasis={emphasis} />
            </div>
        </div>
    );
};

export default withTranslation()(HeroVideo);
