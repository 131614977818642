import React from 'react';
import { graphql } from 'gatsby';

import { sortAlphabeticallyByName } from '../utilties/helpers';
import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import HeroBanner from '../components/hero-banner/component.banner';
import PostOverview from '../components/component.post-overview';
import SectionNav from '../components/component.SectionNav';
import CookieNotice from "../components/component.cookie-notice";
import Pagination from '../components/component.pagination';

/**
 * Updates category slug to match URL structure
 *
 * @param {*} categoriesList
 * @returns
 */
function updateCategoryLinks(categoriesList) {
    const categories = categoriesList.map(category => {
        // if (category.slug !== 'news' && !category.slug.includes('category/')) {
        if (!category.slug.includes('category/')) {
            category.slug = `category/${category.slug}`;
        }

        return category;
    });

    return categories;
}

/**
 * Moves an array entry to the first position
 *
 * @param {*} { dataToSort = [], first = '' }
 * @returns
 */
function startWith({ dataToSort = [], first = '' }) {
    const sortedData = [...dataToSort];

    sortedData.sort((x,y) => {
        return x.name === first ? -1 : y === first ? 1 : 0;
    });

    return sortedData;
}

const News = ({ data, pageContext }) => {

    const category = data.wordpress.category;
    const posts = category ? category.posts.nodes : null;
    const allLanguageCategories = data.wordpress.categories.nodes;
    const currentCategory = allLanguageCategories.filter(item => (item.name === pageContext.name));
    const seo = currentCategory.length ? currentCategory[0].seo : allLanguageCategories[0].seo;
    const bannerImage = (category && category.ACFPostCategoryFields.image) ? category.ACFPostCategoryFields.image.imageFile : false; 



    // find categories relevant to this page's language:
    let categories = [];
    let pageContextLanguageCode = "EN";
    if (typeof pageContext.language !== "undefined") {
        pageContextLanguageCode = pageContext.language.code;
    }
    let thisCategory, thisCategoryLanguage;
    for(thisCategory in allLanguageCategories) {
        thisCategoryLanguage = "EN";
        if(typeof allLanguageCategories[thisCategory].language !== "undefined") {
            thisCategoryLanguage = allLanguageCategories[thisCategory].language.code;
        }
        if(pageContextLanguageCode == thisCategoryLanguage){
            categories.push(allLanguageCategories[thisCategory]);
        }
    }


    // Order categories alphabetically - Stops them reordering when switching category
    // Used because OrderBy GraphQl does not appear to work
    const orderedAlphabeticallyCategories = sortAlphabeticallyByName(categories);

    // Move news to be the first item (this shows all posts)
    const orderedCategories = startWith({
        dataToSort: orderedAlphabeticallyCategories,
        first: 'News'
    });
    
    // Add /category to slug so it matches the gatsby-node slug creation and old website structure
    const categoriesList = updateCategoryLinks(orderedCategories);
   
    return (
        <Layout language={pageContext.language} translations={pageContext.translations}>
            <SEO title={pageContext.name} seo={seo} translations={pageContext.translations} slug={pageContext.slug} language={pageContext.language} />
            <HeroBanner title={pageContext.name} image={bannerImage} />
            <SectionNav title="Categories" links={categoriesList} partiallyActive />

            <div className="container xs-mt-20 md-mt-40 xs-mb-20 md-mb-40">
                <div className="row">
                    <div className="col">
                        {posts &&
                            <div className="row">
                                {posts.map((post, index) => (
                                    <div key={index} className="col-md-4 xs-mb-40">
                                        <PostOverview post={post} language={pageContext.language} />
                                    </div>
                                ))}
                            </div>
                        }
                        {!posts &&
                            <div className="row">
                                <div className="col">
                                    <p>There are no posts available.</p>
                                </div>
                            </div>
                        }
                        <Pagination slugPrefix={pageContext.slugPrefix} numPages={pageContext.numPages} currentPage={pageContext.pageNumber} />
                    </div>
                </div>
            </div>
            <CookieNotice />
        </Layout>
    );
};

export const query = graphql`
    query ($id: ID!, $endCursor: String, $postsPerPage: Int!) {
        wordpress {
            categories(first: 10000, where: { hideEmpty: true }) {
                nodes {
                    id
                    slug
                    name
                    language {
                        code
                        locale
                    }
                    translations {
                        uri
                        language {
                            code
                            locale
                        }
                    }
                    seo {
                        ...SeoFields
                    }
                }
            }
            category(id: $id) {
                ACFPostCategoryFields {
                    image {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1140) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                posts(
                    first: $postsPerPage,
                    after: $endCursor,
                    where: { status: PUBLISH }
                ) {
                    nodes {
                        title
                        slug
                        uri
                        date
                        language {
                            code
                            locale
                        }
                        translations {
                            uri
                            language {
                                code
                                locale
                            }
                        }
                        featuredImage {
                            node {
                                altText
                                sourceUrl
                                imageFile {
                                    childImageSharp {
                                        thumbnail: fixed(width: 347, height: 215) {
                                            ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default News;
