import React from 'react';
import { Link } from 'gatsby';
import { withTranslation } from 'react-i18next';

const Pagination = ({ numPages, currentPage, slugPrefix, t, i18n }) => {
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? slugPrefix : `${slugPrefix}${currentPage - 1}/`;
    const nextPage = `${slugPrefix}${currentPage + 1}/`;

    return (
        <>
            {numPages > 1 &&
                <>
                    <nav role="navigation" aria-label="Pagination Navigation" className="c-pagination">
                        <ul className="c-pagination__list">
                            {!isFirst && (
                                <li className="c-pagination__item c-pagination__item--prev">
                                    <Link aria-label={t('pagination.previous-page-label')} className="c-pagination__link c-pagination__link--prev" to={prevPage} rel="prev">
                                        {t('pagination.prev-label')}
                                    </Link>
                                </li>
                            )}
                            {Array.from({ length: numPages }, (_, i) => {
                                const isCurrentPage = (i + 1 === currentPage) ? true : false;

                                return (
                                    <li className="c-pagination__item c-pagination__item--page">
                                        <Link
                                            aria-label={isCurrentPage ? `${t('pagination.current-page-label')}, ${t('pagination.page-no-label-a')} ${i + 1}` : `${t('pagination.go-to-label')} ${i + 1}`}
                                            aria-current={isCurrentPage ? true : false}
                                            className="c-pagination__link c-pagination__link--page"
                                            key={`pagination-number-${i + 1}`}
                                            activeClassName="c-pagination__link--current"
                                            to={i === 0 ? `${slugPrefix}` : `${slugPrefix}${i + 1}`}
                                        >
                                            {i + 1}
                                        </Link>
                                    </li>
                                );
                            })}
                            {!isLast && (
                                <li className="c-pagination__item c-pagination__item--next">
                                    <Link aria-label={t('pagination.next-page-label')} className="c-pagination__link c-pagination__link--next" to={nextPage} rel="next">
                                        {t('pagination.next-label')}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </nav>

                    <span className="c-pagination__support">{`${t('pagination.page-no-label-a')} ${currentPage} ${t('pagination.page-no-label-b')} ${numPages}`}</span>
                </>
            }
        </>
    );
};

export default withTranslation()(Pagination);
