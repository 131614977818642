import React from 'react';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import HeroCaption from './component.caption';

const HeroImage = ({ title, subtitle, emphasis = false, image }) => (
    (image &&
        <BackgroundImage
            Tag="div"
            className={emphasis ? "c-hero-banner__wrapper c-hero-banner__wrapper--image c-hero-banner__wrapper--emphasis" : "c-hero-banner__wrapper c-hero-banner__wrapper--image"}
            fluid={image}
        >
            <Img className="c-hero-banner__image" fluid={image} alt={title} />
            <HeroCaption title={title} subtitle={subtitle} emphasis={emphasis} />
        </BackgroundImage>    
    )
);

export default HeroImage;