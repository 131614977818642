import React from 'react';
import { useSpring, animated } from 'react-spring';

const HeroCaption = ({ title, subtitle, emphasis }) => {
    const h1AniStyles = useSpring({
        to: { opacity: 1, height: emphasis ? 55 : 40 },
        from: { opacity: 0, height: 0 },
    });
    
    const h2AniStyles = useSpring({
        to: { opacity: 1, height: 20 },
        from: { opacity: 0, height: 0 },
    });

    return (
        (title || subtitle) && (
            <div className="c-hero-banner__caption">
                {title && (
                    <animated.h1 style={h1AniStyles} className="c-hero-banner__title" dangerouslySetInnerHTML={{ __html: title }}></animated.h1>
                )}
                {subtitle && (
                    <animated.h2 style={h2AniStyles} className="c-hero-banner__subtitle">{subtitle}</animated.h2>
                )}
            </div>
        )
    );
};

export default HeroCaption;